body {
  padding: 0;
  margin: 0;
  background-color: #f3f3f3;
  font-family: 'Commissioner', sans-serif;
}

h1 {
  font-size: 20px;
  font-weight: 500;
  line-height: 0;
  letter-spacing: 0;
  text-align: left;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0;
  margin-inline-end: 0;
  color: rgba(17, 18, 23, 1);
}

h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 0;
  letter-spacing: 0;
  text-align: left;
  margin-block-start: 1em !important;
  margin-block-end: 1em !important;
  margin-inline-start: 0;
  margin-inline-end: 0;
  color: rgba(113, 117, 131, 1);
}